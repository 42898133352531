import { Auth0ContextInterface } from '@auth0/auth0-react';

import { UserModel } from 'models/user.model';
import {
  CommercialAndFinancialAssessmentValue,
  InstitutionalAndEfficiencyAssessmentValue,
  TechnicalAndEfficiencyAssessment,
  UtilityAssessment,
  UtilityAssessmentValueType,
} from 'models/utility-assessment.model';

import { axiosGet, axiosUpdate } from './external-api.service';

export const utilityAssessmentService = {
  getInstitutionalAndEfficiencyAssessmentFormByOrganizationId: (
    auth: Auth0ContextInterface<UserModel>,
    organizationId: string | undefined,
  ) => {
    if (!organizationId) return;

    return axiosGet<Record<string, InstitutionalAndEfficiencyAssessmentValue>>(
      auth,
      `/v2/utility-assessment/institutional-and-efficiency-assessment/${organizationId}`,
    );
  },

  getTechnicalAndEfficiencyAssessmentFormByOrganizationId: (
    auth: Auth0ContextInterface<UserModel>,
    organizationId: string | undefined,
  ) => {
    if (!organizationId) return;

    return axiosGet<TechnicalAndEfficiencyAssessment>(
      auth,
      `/v2/utility-assessment/technical-and-efficiency-assessment/${organizationId}`,
    );
  },

  getCommercialAndFinancialAssessmentFormByOrganizationId: (
    auth: Auth0ContextInterface<UserModel>,
    organizationId: string | undefined,
  ) => {
    if (!organizationId) return;

    return axiosGet<Record<string, CommercialAndFinancialAssessmentValue>>(
      auth,
      `/v2/utility-assessment/commercial-and-financial-assessment/${organizationId}`,
    );
  },

  updateUtilityAssessmentFormByOrganizationId: (
    auth: Auth0ContextInterface<UserModel>,
    organizationId: string | undefined,
    data: {
      path: string;
      value?: UtilityAssessmentValueType;
    },
  ) => {
    if (!organizationId) return;

    return axiosUpdate<
      UtilityAssessment,
      { path: string; value?: UtilityAssessmentValueType }
    >(auth, `/v2/utility-assessment/${organizationId}`, data);
  },
};
