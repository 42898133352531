import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { FormControlLabel, TextField, Typography } from '@mui/material';

import { COMMON_FORM_CONTROL_LABEL_STYLES } from 'constants/index';

import { PercentageInputFieldProps } from './types';

export const PercentageInputField: FC<PercentageInputFieldProps> = ({
  name,
  label,
  onBlur,
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      rules={{ min: 0, max: 100 }}
      render={({ field, fieldState: { error, isDirty } }) => (
        <FormControlLabel
          sx={COMMON_FORM_CONTROL_LABEL_STYLES}
          control={
            <TextField
              {...field}
              error={!!error}
              helperText={error?.message}
              value={field.value || ''}
              onBlur={() => isDirty && onBlur()}
              size="small"
              fullWidth
              type="number"
              inputProps={{ min: 0, max: 100 }}
            />
          }
          label={
            <Typography sx={{ fontSize: 14, fontWeight: 700, mb: 0.5 }}>
              {label}
            </Typography>
          }
          labelPlacement="top"
        />
      )}
    />
  );
};
