import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormControlLabel, MenuItem, Select, Typography } from '@mui/material';

import { COMMON_FORM_CONTROL_LABEL_STYLES } from 'constants/index';

import { RenderSelectProps } from './types';

export const RenderSelect: FC<RenderSelectProps> = ({
  name,
  options,
  label,
  isDirtyCallback,
}) => {
  const { control } = useFormContext();
  const { t } = useTranslation();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error, isDirty } }) => (
        <FormControlLabel
          sx={COMMON_FORM_CONTROL_LABEL_STYLES}
          control={
            <Select
              {...field}
              error={!!error}
              size="small"
              fullWidth
              value={field.value || ''}
              onBlur={() => isDirty && isDirtyCallback(field.name)}
            >
              {options.map((option) => (
                <MenuItem key={option} value={option}>
                  {t(`dropdown_options.${option}`, option)}
                </MenuItem>
              ))}
            </Select>
          }
          label={
            <Typography sx={{ fontSize: 14, fontWeight: 700, mb: 0.5 }}>
              {label}
            </Typography>
          }
          labelPlacement="top"
        />
      )}
    />
  );
};
