import { MessageType } from 'models/comment.model';
import { UtilityAssessmentAttachment } from 'models/utility-assessment.model';

export const getFormattedValue = (
  value:
    | number
    | MessageType
    | Record<string, UtilityAssessmentAttachment[] | MessageType>
    | null,
): string => {
  if (value === null || typeof value === 'undefined') {
    return 'dropdown_options.n_/_a';
  }

  return typeof value === 'object' ? JSON.stringify(value) : String(value);
};
