import { AxiosError } from 'axios';
import { Severity } from 'context/types';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuth0 } from '@auth0/auth0-react';

import { useMutation } from 'react-query';
import { queryKeys } from 'react-query/constants';
import { queryClient } from 'react-query/queryClient';

import { UserModel } from 'models/user.model';

import { projectFactsheetService } from 'services/project-factsheet.service';

import { AppContext } from 'context';

import { UseUpdateProjectFactsheetByProjectId } from './types';

export const useUpdateProjectFactsheetByProjectId = () => {
  const auth = useAuth0<UserModel>();
  const { t } = useTranslation();
  const {
    notification: { showNotification },
  } = useContext(AppContext);

  const { mutateAsync, isLoading } = useMutation({
    mutationKey: [queryKeys.updateProjectFactsheetData],
    mutationFn: ({ data, projectId }: UseUpdateProjectFactsheetByProjectId) =>
      projectFactsheetService.updateProjectFactsheetData(auth, projectId, data),
    onSuccess: (data) => {
      queryClient.setQueryData([queryKeys.getProjectFactsheetData], data);
    },
    onError: (error: AxiosError) => {
      const errorMessage =
        (error.response?.data as { message: string })?.message ||
        t('errors.unknown_error_occurred');

      showNotification({
        isShowingNotification: true,
        type: Severity.Error,
        message: errorMessage,
      });
    },
  });

  return {
    mutateAsync,
    isLoading,
  };
};
