import { Auth0ContextInterface } from '@auth0/auth0-react';

import { ProjectFactsheetFormData } from 'models/project-factsheet-form.model';
import { UserModel } from 'models/user.model';

import { axiosGet, axiosUpdate } from './external-api.service';

export const projectFactsheetService = {
  getProjectFactsheetData: (
    auth: Auth0ContextInterface<UserModel>,
    projectId: string | undefined,
  ) => {
    if (!projectId) return;

    return axiosGet<ProjectFactsheetFormData>(
      auth,
      `/v2/project-factsheet/${projectId}`,
    );
  },

  updateProjectFactsheetData: (
    auth: Auth0ContextInterface<UserModel>,
    projectId: string | undefined,
    data: { path: string; value: string },
  ) => {
    return axiosUpdate<
      ProjectFactsheetFormData,
      { path: string; value: string }
    >(auth, `/v2/project-factsheet/${projectId}`, data);
  },
};
