import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { TextInput } from 'components/text-input/text-input';

import { RenderTextInputProps } from './types';

export const RenderTextInput: FC<RenderTextInputProps> = ({
  name,
  label,
  type = 'text',
  isMultiline,
  isDirtyCallback,
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { isDirty } }) => (
        <TextInput
          {...field}
          type={type}
          multiline={isMultiline}
          rows={isMultiline ? 5 : 1}
          label={label}
          onBlur={() => isDirty && isDirtyCallback(field.name)}
        />
      )}
    />
  );
};
