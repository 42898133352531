import { FC, useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';

import { toSnakeCase } from 'utils';

import { PERCENTAGE_FIELD_LABELS } from './constants';
import { PercentageInputField } from './percentage-input-field';
import { PercentageFieldProps } from './types';

export const PercentageField: FC<PercentageFieldProps> = ({
  translationKey,
  sectionKey,
  questionId,
  onUpdateForm,
}) => {
  const { t } = useTranslation();
  const { watch, trigger, setError, getValues } = useFormContext();

  const data = getValues(`${sectionKey}.${questionId}.value`) ?? {};

  const fieldData = Object.keys(data).map((dataKey) => ({
    dataKey,
    percentage: Number(watch(`${sectionKey}.${questionId}.value.${dataKey}`)),
  }));

  const totalPercentages = useMemo(
    () => fieldData.reduce((sum, { percentage }) => sum + (percentage ?? 0), 0),
    [fieldData],
  );

  const handleUpdateForm = useCallback(
    async (key: string) => {
      const isValidField = await trigger(key);

      if (!isValidField) {
        return setError(key, {
          type: 'manual',
          message: t('errors.values_min_max_error'),
        });
      }

      if (totalPercentages > 100) {
        return setError(key, {
          type: 'manual',
          message: t('errors.values_total_exceeded_error'),
        });
      }

      onUpdateForm(key);
    },
    [onUpdateForm, trigger, setError, totalPercentages, t],
  );

  return (
    <Box sx={{ width: 1, display: 'flex', gap: 1 }}>
      {PERCENTAGE_FIELD_LABELS.map((field) => (
        <PercentageInputField
          key={field}
          name={`${sectionKey}.${questionId}.value.${field}`}
          label={t(
            `${translationKey}.${toSnakeCase(sectionKey)}.${toSnakeCase(
              questionId,
            )}.${field}`,
          )}
          onBlur={() =>
            handleUpdateForm(`${sectionKey}.${questionId}.value.${field}`)
          }
        />
      ))}
    </Box>
  );
};
