import { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';

import Tab from '@mui/material/Tab';

import { StyledTabs } from './styles';
import { TabsProps } from './types';

export const Tabs: FC<TabsProps> = ({
  defaultValue,
  tabsData,
  orientation = 'horizontal',
  variant = 'default',
}) => {
  const { pathname } = useLocation();
  const routeMatch =
    tabsData.find((tab) => pathname.includes(tab.value))?.value || defaultValue;

  return (
    <StyledTabs
      value={routeMatch}
      variant="fullWidth"
      orientation={orientation}
      tabVariant={variant}
    >
      {tabsData.map(({ label, path, value, icon, iconPosition = 'start' }) => (
        <Tab
          iconPosition={iconPosition}
          icon={icon}
          label={label}
          value={value}
          key={label}
          to={path}
          component={Link}
        />
      ))}
    </StyledTabs>
  );
};
