import {
  useGetCommercialAndFinancialAssessmentFormByOrganizationId,
  useGetInstitutionalAndEfficiencyAssessmentFormByOrganizationId,
  useGetTechnicalAndEfficiencyAssessmentFormByOrganizationId,
} from 'hooks/queries/utility-assessment/utility-assessment';
import { createContext } from 'react';
import { useParams } from 'react-router-dom';

import {
  initialUtilityAssessmentCommercialAndFinancialAssessmentDataContext,
  initialUtilityAssessmentInstitutionalAndEfficiencyAssessmentDataContext,
  initialUtilityAssessmentTechnicalAndEfficiencyAssessmentDataContext,
} from './constants';
import {
  UtilityAssessmentContextProviderProps,
  UtilityAssessmentContextType,
} from './types';

const initialUtilityAssessmentContext: UtilityAssessmentContextType = {
  institutionalAndEfficiencyAssessmentTab: {
    isFetchingInstitutionalAndEfficiencyAssessmentData: false,
    data: {},
  },
  technicalAndEfficiencyAssessmentTab: {
    isFetchingTechnicalAndEfficiencyAssessmentData: false,
    preliminaryQuestions: {},
    generalData: {},
    artesianWells: [],
    workOfWaterSupplyService: [],
    operationOfBoostingPumpingStations: [],
    operationOfWastewaterPumpingStations: [],
    comments: [],
  },
  commercialAndFinancialAssessmentTab: {
    isFetchingCommercialAndFinancialAssessmentData: false,
    data: {},
  },
};

export const UtilityAssessmentContext = createContext(
  initialUtilityAssessmentContext,
);

export const UtilityAssessmentContextProvider = ({
  children,
}: UtilityAssessmentContextProviderProps) => {
  const { organizationId } = useParams();
  const {
    data: technicalAndEfficiencyAssessmentData,
    isFetching: isFetchingTechnicalAndEfficiencyAssessmentData,
  } =
    useGetTechnicalAndEfficiencyAssessmentFormByOrganizationId(organizationId);
  const {
    data: institutionalAndEfficiencyAssessmentData,
    isFetching: isFetchingInstitutionalAndEfficiencyAssessmentData,
  } =
    useGetInstitutionalAndEfficiencyAssessmentFormByOrganizationId(
      organizationId,
    );
  const {
    data: commercialAndFinancialAssessmentData,
    isFetching: isFetchingCommercialAndFinancialAssessmentData,
  } =
    useGetCommercialAndFinancialAssessmentFormByOrganizationId(organizationId);

  return (
    <UtilityAssessmentContext.Provider
      value={{
        institutionalAndEfficiencyAssessmentTab: {
          isFetchingInstitutionalAndEfficiencyAssessmentData,
          data: {
            ...(institutionalAndEfficiencyAssessmentData ??
              initialUtilityAssessmentInstitutionalAndEfficiencyAssessmentDataContext.data),
          },
        },
        technicalAndEfficiencyAssessmentTab: {
          isFetchingTechnicalAndEfficiencyAssessmentData,
          ...(technicalAndEfficiencyAssessmentData ??
            initialUtilityAssessmentTechnicalAndEfficiencyAssessmentDataContext),
        },
        commercialAndFinancialAssessmentTab: {
          isFetchingCommercialAndFinancialAssessmentData,
          data: {
            ...(commercialAndFinancialAssessmentData ??
              initialUtilityAssessmentCommercialAndFinancialAssessmentDataContext.data),
          },
        },
      }}
    >
      {children}
    </UtilityAssessmentContext.Provider>
  );
};
