import { Severity } from 'context/types';
import { FC, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useParams } from 'react-router-dom';
import {
  InvestmentPlanRoutes,
  Routes,
  UtilityAssessmentRoutes,
} from 'routes/constants';

import { Box, Button, Grid } from '@mui/material';

import { useAuth0 } from '@auth0/auth0-react';

import { InvestmentPlanLayout } from 'components/investment-plan-layout/investment-plan-layout';
import { PaperLayout } from 'components/paper-layout/paper-layout';
import { Tabs } from 'components/tabs/tabs';

import { UserModel } from 'models/user.model';

import { ReactComponent as IosShareRoundedIcon } from 'assets/file_download.svg';

import { instance, logoutOnInvalidToken } from 'services/external-api.service';

import { AppContext } from 'context';

import { onFileDownload } from 'utils';

import { UtilityAssessmentContextProvider } from './context';

export const UtilityAssessmentPage: FC = () => {
  const auth = useAuth0<UserModel>();
  const { t } = useTranslation();
  const { organizationId } = useParams();
  const {
    notification: { showNotification },
  } = useContext(AppContext);

  const [
    isUtilityAssessmentTableExporting,
    setIsUtilityAssessmentTableExporting,
  ] = useState(false);

  const downloadExcelUtilityAssessmentTable = async () => {
    if (!organizationId) return;

    try {
      setIsUtilityAssessmentTableExporting(true);

      const token = await logoutOnInvalidToken(auth);
      const response = await instance.get(
        `v2/utility-assessment/${organizationId}/export-table`,
        {
          headers: { Authorization: `Bearer ${token}` },
          responseType: 'blob',
        },
      );

      onFileDownload(response.data, 'UtilityAssessment.xlsx');
    } catch (error) {
      showNotification({
        isShowingNotification: true,
        type: Severity.Error,
        message: t('errors.report.generate_report', {
          error: (error as any)?.message,
        }),
      });
    } finally {
      setIsUtilityAssessmentTableExporting(false);
    }
  };

  const commonPath = `/${Routes.ORGANIZATIONS}/${organizationId}/${Routes.INVESTMENT_PLAN}/${InvestmentPlanRoutes.UTILITY_ASSESSMENT}`;

  const getUtilityAssessmentTabs = [
    {
      label: t(
        'utility_assessment_page.institutional_and_efficiency_assessment.title',
      ),
      value: UtilityAssessmentRoutes.INSTITUTIONAL_AND_EFFICIENCY_ASSESSMENT,
      path: `${commonPath}/${UtilityAssessmentRoutes.INSTITUTIONAL_AND_EFFICIENCY_ASSESSMENT}`,
    },
    {
      label: t(
        'utility_assessment_page.technical_and_efficiency_assessment.title',
      ),
      value: UtilityAssessmentRoutes.TECHNICAL_AND_EFFICIENCY_ASSESSMENT,
      path: `${commonPath}/${UtilityAssessmentRoutes.TECHNICAL_AND_EFFICIENCY_ASSESSMENT}`,
    },
    {
      label: t(
        'utility_assessment_page.commercial_and_financial_assessment.title',
      ),
      value: UtilityAssessmentRoutes.COMMERCIAL_AND_FINANCIAL_ASSESSMENT,
      path: `${commonPath}/${UtilityAssessmentRoutes.COMMERCIAL_AND_FINANCIAL_ASSESSMENT}`,
    },
  ];

  return (
    <UtilityAssessmentContextProvider>
      <InvestmentPlanLayout
        sectionName="utility_assessment_page"
        renderTooltip
        exportingButton={
          <Button
            onClick={downloadExcelUtilityAssessmentTable}
            variant="outlined"
            disabled={isUtilityAssessmentTableExporting}
            startIcon={<IosShareRoundedIcon />}
          >
            {t('utility_assessment_page.export_utility_assessment_table')}
          </Button>
        }
      >
        <Grid container justifyContent="center">
          <Grid item xs={12}>
            <PaperLayout borderRadius={4}>
              <Tabs
                tabsData={getUtilityAssessmentTabs}
                defaultValue={
                  UtilityAssessmentRoutes.INSTITUTIONAL_AND_EFFICIENCY_ASSESSMENT
                }
              />

              <Box>
                <Outlet />
              </Box>
            </PaperLayout>
          </Grid>
        </Grid>
      </InvestmentPlanLayout>
    </UtilityAssessmentContextProvider>
  );
};
