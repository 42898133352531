import { useUpdateUtilityAssessmentFormByOrganizationId } from 'hooks/mutations/utility-assessment/utility-assessment';
import { useContext, useEffect } from 'react';
import { Controller, FieldPath, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  TechnicalAndEfficiencyAssessmentRoutes,
  UtilityAssessmentRoutes,
} from 'routes/constants';

import {
  Divider,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@mui/material';

import { queryKeys } from 'react-query/constants';

import { UtilityAssessmentContext } from 'pages/utility-assessment-page/context';
import { UtilityAssessmentForms } from 'pages/utility-assessment-page/types';

import { InputLinkUpload } from 'components/link-upload/link-upload';
import { PageLoader } from 'components/page-loader/page-loader';
import { TextInput } from 'components/text-input/text-input';

import { COMMON_FORM_CONTROL_LABEL_STYLES } from 'constants/index';

import { AppContext } from 'context';

import { formatUtilityAssessmentValue, getValue, toSnakeCase } from 'utils';

import { useIsEnLanguage } from 'hooks/hooks/useIsEnLanguage';

import { StyledSectionTitle } from './styles';
import { FormValues } from './types';

export const GeneralDataTab = () => {
  const { t } = useTranslation();
  const { organizationId } = useParams();
  const { currentLanguage } = useIsEnLanguage();
  const { watch, control } = useFormContext();
  const {
    technicalAndEfficiencyAssessmentTab: {
      generalData,
      isFetchingTechnicalAndEfficiencyAssessmentData: isFetching,
    },
  } = useContext(UtilityAssessmentContext);
  const {
    commentsUniquePathId: { setCommentsUniquePathId },
  } = useContext(AppContext);

  const { mutateAsync: updateUtilityAssessmentForm } =
    useUpdateUtilityAssessmentFormByOrganizationId(
      queryKeys.getTechnicalAndEfficiencyAssessmentFormByOrganizationId,
      UtilityAssessmentForms.TECHNICAL_AND_EFFICIENCY_ASSESSMENT,
    );

  const handleUpdateFormOnBlur = async (fieldPath: FieldPath<FormValues>) => {
    const value = watch(fieldPath);

    if (value) {
      await updateUtilityAssessmentForm({
        organizationId,
        data: {
          path: `${UtilityAssessmentForms.TECHNICAL_AND_EFFICIENCY_ASSESSMENT}.${fieldPath}`,
          value: formatUtilityAssessmentValue(fieldPath, value),
        },
      });
    }
  };

  useEffect(() => {
    if (!isFetching) {
      const uniqueCommentsPath = `${organizationId}${UtilityAssessmentRoutes.TECHNICAL_AND_EFFICIENCY_ASSESSMENT}${TechnicalAndEfficiencyAssessmentRoutes.GENERAL_DATA}`;

      setCommentsUniquePathId(uniqueCommentsPath);
    }
  }, [isFetching, organizationId, setCommentsUniquePathId]);

  return (
    <Grid container spacing={3}>
      {isFetching ? (
        <PageLoader height="100%" />
      ) : (
        Object.keys(generalData).map((sectionKey, index) => {
          const fieldData = generalData[sectionKey];

          return (
            <Grid item key={sectionKey} xs={12}>
              <StyledSectionTitle>
                {t(
                  `utility_assessment_page.technical_and_efficiency_assessment.general_data.${toSnakeCase(sectionKey)}.title`,
                )}
              </StyledSectionTitle>

              <Grid container spacing={3}>
                {fieldData.map((fieldKey, fieldIndex) => (
                  <Grid item key={fieldKey.key} xs={12}>
                    <Typography sx={{ fontWeight: 800, mb: 1 }}>
                      {t(
                        `utility_assessment_page.technical_and_efficiency_assessment.general_data.${toSnakeCase(sectionKey)}.${toSnakeCase(fieldKey.key)}`,
                      )}
                    </Typography>

                    <Grid container spacing={3}>
                      {Object.keys(fieldKey.years).map((year) => (
                        <Grid item xs key={year}>
                          <Controller
                            name={`generalData.${sectionKey}.${fieldIndex}.years.${year}`}
                            control={control}
                            render={({ field }) => (
                              <TextInput
                                {...field}
                                type="number"
                                label={year}
                                onBlur={() =>
                                  handleUpdateFormOnBlur(field.name)
                                }
                              />
                            )}
                          />
                        </Grid>
                      ))}

                      <Grid item xs={12}>
                        <Controller
                          name={`generalData.${sectionKey}.${fieldIndex}.explanation.${currentLanguage}`}
                          control={control}
                          render={({
                            field,
                            fieldState: { error, isDirty },
                          }) => (
                            <FormControlLabel
                              sx={COMMON_FORM_CONTROL_LABEL_STYLES}
                              control={
                                <TextField
                                  {...field}
                                  value={getValue(field.value, currentLanguage)}
                                  error={!!error}
                                  multiline
                                  rows={7}
                                  helperText={error?.message}
                                  fullWidth
                                  onBlur={() => {
                                    if (isDirty) {
                                      handleUpdateFormOnBlur(field.name);
                                    }
                                  }}
                                />
                              }
                              label={
                                <Typography
                                  sx={{
                                    fontSize: 14,
                                    fontWeight: 700,
                                    mb: 0.5,
                                  }}
                                >
                                  {t('utility_assessment_page.explanation')}
                                </Typography>
                              }
                              labelPlacement="top"
                            />
                          )}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <InputLinkUpload
                          control={control}
                          fieldName={`generalData.${sectionKey}.${fieldIndex}.attachments`}
                          onUpdateTemplate={() =>
                            handleUpdateFormOnBlur(
                              `generalData.${sectionKey}.${fieldIndex}.attachments`,
                            )
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>

              {index < Object.keys(generalData).length - 1 && (
                <Divider sx={{ mt: 3 }} />
              )}
            </Grid>
          );
        })
      )}
    </Grid>
  );
};
