import { AxiosError } from 'axios';
import { Severity } from 'context/types';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuth0 } from '@auth0/auth0-react';

import { useQuery } from 'react-query';
import { queryKeys } from 'react-query/constants';

import { UserModel } from 'models/user.model';

import { utilityAssessmentService } from 'services/utility-assessment.service';

import { AppContext } from 'context';

import {
  UseGetCommercialAndFinancialAssessmentFormByOrganizationId,
  UseGetInstitutionalAndEfficiencyAssessmentByOrganizationId,
  UseGetTechnicalAndEfficiencyAssessmentFormByOrganizationId,
} from './types';

export const useGetInstitutionalAndEfficiencyAssessmentFormByOrganizationId = (
  organizationId?: string,
): UseGetInstitutionalAndEfficiencyAssessmentByOrganizationId => {
  const auth = useAuth0<UserModel>();
  const { t } = useTranslation();
  const {
    notification: { showNotification },
  } = useContext(AppContext);

  const { data, isLoading, isError, isFetching } = useQuery(
    queryKeys.getInstitutionalAndEfficiencyAssessmentFormByOrganizationId,
    () =>
      utilityAssessmentService.getInstitutionalAndEfficiencyAssessmentFormByOrganizationId(
        auth,
        organizationId,
      ),
    {
      enabled: !!organizationId,
      onError: (error: AxiosError) => {
        const errorMessage =
          (error.response?.data as { message: string })?.message ||
          t('errors.unknown_error_occurred');

        showNotification({
          isShowingNotification: true,
          type: Severity.Error,
          message: errorMessage,
        });
      },
    },
  );

  return {
    data,
    isLoading,
    isError,
    isFetching,
  };
};

export const useGetTechnicalAndEfficiencyAssessmentFormByOrganizationId = (
  organizationId?: string,
): UseGetTechnicalAndEfficiencyAssessmentFormByOrganizationId => {
  const auth = useAuth0<UserModel>();
  const { t } = useTranslation();
  const {
    notification: { showNotification },
  } = useContext(AppContext);

  const { data, isLoading, isError, isFetching } = useQuery(
    queryKeys.getTechnicalAndEfficiencyAssessmentFormByOrganizationId,
    () =>
      utilityAssessmentService.getTechnicalAndEfficiencyAssessmentFormByOrganizationId(
        auth,
        organizationId,
      ),
    {
      enabled: !!organizationId,
      onError: (error: AxiosError) => {
        const errorMessage =
          (error.response?.data as { message: string })?.message ||
          t('errors.unknown_error_occurred');

        showNotification({
          isShowingNotification: true,
          type: Severity.Error,
          message: errorMessage,
        });
      },
    },
  );

  return {
    data,
    isLoading,
    isError,
    isFetching,
  };
};

export const useGetCommercialAndFinancialAssessmentFormByOrganizationId = (
  organizationId?: string,
): UseGetCommercialAndFinancialAssessmentFormByOrganizationId => {
  const auth = useAuth0<UserModel>();
  const { t } = useTranslation();
  const {
    notification: { showNotification },
  } = useContext(AppContext);

  const { data, isLoading, isError, isFetching } = useQuery(
    queryKeys.getCommercialAndFinancialAssessmentFormByOrganizationId,
    () =>
      utilityAssessmentService.getCommercialAndFinancialAssessmentFormByOrganizationId(
        auth,
        organizationId,
      ),
    {
      enabled: !!organizationId,
      onError: (error: AxiosError) => {
        const errorMessage =
          (error.response?.data as { message: string })?.message ||
          t('errors.unknown_error_occurred');

        showNotification({
          isShowingNotification: true,
          type: Severity.Error,
          message: errorMessage,
        });
      },
    },
  );

  return {
    data,
    isLoading,
    isError,
    isFetching,
  };
};
