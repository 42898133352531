import { useUpdateUtilityAssessmentFormByOrganizationId } from 'hooks/mutations/utility-assessment/utility-assessment';
import { FC, useContext, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { UtilityAssessmentRoutes } from 'routes/constants';

import { queryKeys } from 'react-query/constants';

import { AppContext } from 'context';

import { formatUtilityAssessmentValue } from 'utils';

import { UtilityAssessmentContext } from '../context';
import { UtilityAssessmentForms } from '../types';
import { CommercialAndFinancialAssessmentForm } from './commercial-and-financial-assessment-form/commercial-and-financial-assessment-form';

export const CommercialAndFinancialAssessmentPanel: FC = () => {
  const { organizationId } = useParams();
  const {
    commentsUniquePathId: { setCommentsUniquePathId },
  } = useContext(AppContext);
  const { commercialAndFinancialAssessmentTab } = useContext(
    UtilityAssessmentContext,
  );
  const { isFetchingCommercialAndFinancialAssessmentData: isFetching, data } =
    commercialAndFinancialAssessmentTab;

  const methods = useForm({ defaultValues: undefined });
  const { reset, watch } = methods;

  const { mutateAsync: updateUtilityAssessmentForm } =
    useUpdateUtilityAssessmentFormByOrganizationId(
      queryKeys.getCommercialAndFinancialAssessmentFormByOrganizationId,
      UtilityAssessmentForms.COMMERCIAL_AND_FINANCIAL_ASSESSMENT,
    );

  const handleUpdateFormOnBlur = async (fieldPath: string) => {
    const value = watch(fieldPath);

    if (value === null) return;

    await updateUtilityAssessmentForm({
      organizationId,
      data: {
        path: `${UtilityAssessmentForms.COMMERCIAL_AND_FINANCIAL_ASSESSMENT}.${fieldPath}`,
        value: formatUtilityAssessmentValue(fieldPath, value),
      },
    });
  };

  useEffect(() => {
    if (!isFetching && data) {
      reset(data);
    }
  }, [data, isFetching, reset]);

  useEffect(() => {
    if (!isFetching) {
      const uniqueCommentsPath = `${organizationId}${UtilityAssessmentRoutes.COMMERCIAL_AND_FINANCIAL_ASSESSMENT}`;

      setCommentsUniquePathId(uniqueCommentsPath);
    }
  }, [isFetching, organizationId, setCommentsUniquePathId]);

  return (
    <FormProvider {...methods}>
      <form>
        <CommercialAndFinancialAssessmentForm
          onUpdateForm={handleUpdateFormOnBlur}
        />
      </form>
    </FormProvider>
  );
};
