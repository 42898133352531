import { FC, useContext, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Outlet, useParams } from 'react-router-dom';
import {
  InvestmentPlanRoutes,
  Routes,
  TechnicalAndEfficiencyAssessmentRoutes,
  UtilityAssessmentRoutes,
} from 'routes/constants';

import { Grid } from '@mui/material';

import { Tabs } from 'components/tabs/tabs';

import { StyledStickyGridItem, theme } from 'configs/theme';

import { UtilityAssessmentContext } from '../context';

export const TechnicalAndEfficiencyAssessmentPanel: FC = () => {
  const { t } = useTranslation();
  const { organizationId } = useParams();
  const { technicalAndEfficiencyAssessmentTab } = useContext(
    UtilityAssessmentContext,
  );
  const { isFetchingTechnicalAndEfficiencyAssessmentData: isFetching } =
    technicalAndEfficiencyAssessmentTab;

  const commonPath = `/${Routes.ORGANIZATIONS}/${organizationId}/${Routes.INVESTMENT_PLAN}/${InvestmentPlanRoutes.UTILITY_ASSESSMENT}/${UtilityAssessmentRoutes.TECHNICAL_AND_EFFICIENCY_ASSESSMENT}`;

  const methods = useForm({
    defaultValues: technicalAndEfficiencyAssessmentTab,
  });
  const { reset } = methods;

  const getTechnicalAndEfficiencyAssessmentPanelTabs = [
    {
      label: t(
        'utility_assessment_page.technical_and_efficiency_assessment.preliminary_questions.title',
      ),
      value: TechnicalAndEfficiencyAssessmentRoutes.PRELIMINARY_QUESTIONS,
      path: `${commonPath}/${TechnicalAndEfficiencyAssessmentRoutes.PRELIMINARY_QUESTIONS}`,
    },
    {
      label: t(
        'utility_assessment_page.technical_and_efficiency_assessment.general_data.title',
      ),
      value: TechnicalAndEfficiencyAssessmentRoutes.GENERAL_DATA,
      path: `${commonPath}/${TechnicalAndEfficiencyAssessmentRoutes.GENERAL_DATA}`,
    },
    {
      label: t(
        'utility_assessment_page.technical_and_efficiency_assessment.operation_of_artesian_wells.title',
      ),
      value: TechnicalAndEfficiencyAssessmentRoutes.OPERATION_OF_ARTESIAN_WELLS,
      path: `${commonPath}/${TechnicalAndEfficiencyAssessmentRoutes.OPERATION_OF_ARTESIAN_WELLS}`,
    },
    {
      label: t(
        'utility_assessment_page.technical_and_efficiency_assessment.work_of_the_water_supply_service.title',
      ),
      value:
        TechnicalAndEfficiencyAssessmentRoutes.WORK_OF_THE_WATER_SUPPLY_SERVICE,
      path: `${commonPath}/${TechnicalAndEfficiencyAssessmentRoutes.WORK_OF_THE_WATER_SUPPLY_SERVICE}`,
    },
    {
      label: t(
        'utility_assessment_page.technical_and_efficiency_assessment.operation_of_boosting_pumping_stations.title',
      ),
      value:
        TechnicalAndEfficiencyAssessmentRoutes.OPERATION_OF_BOOSTING_PUMPING_STATIONS,
      path: `${commonPath}/${TechnicalAndEfficiencyAssessmentRoutes.OPERATION_OF_BOOSTING_PUMPING_STATIONS}`,
    },
    {
      label: t(
        'utility_assessment_page.technical_and_efficiency_assessment.operation_of_wastewater_pumping_stations.title',
      ),
      value:
        TechnicalAndEfficiencyAssessmentRoutes.OPERATION_OF_WASTEWATER_PUMPING_STATIONS,
      path: `${commonPath}/${TechnicalAndEfficiencyAssessmentRoutes.OPERATION_OF_WASTEWATER_PUMPING_STATIONS}`,
    },
  ];

  useEffect(() => {
    if (!isFetching && technicalAndEfficiencyAssessmentTab) {
      reset(technicalAndEfficiencyAssessmentTab);
    }
  }, [technicalAndEfficiencyAssessmentTab, isFetching, reset]);

  return (
    <Grid container>
      <StyledStickyGridItem
        item
        xs={3}
        sx={{ borderRight: `1px solid ${theme.palette.grey[200]}` }}
      >
        <Tabs
          tabsData={getTechnicalAndEfficiencyAssessmentPanelTabs}
          defaultValue={
            TechnicalAndEfficiencyAssessmentRoutes.PRELIMINARY_QUESTIONS
          }
          orientation="vertical"
          variant="button"
        />
      </StyledStickyGridItem>

      <StyledStickyGridItem item xs>
        <FormProvider {...methods}>
          <form>
            <Outlet />
          </form>
        </FormProvider>
      </StyledStickyGridItem>
    </Grid>
  );
};
