import { AgGridReact } from 'ag-grid-react';
import {
  useDeleteProject,
  useGetInvestmentProjectMetadata,
  useGetInvestmentProjects,
} from 'hooks/queries/investment-project/investment-project';
import { useGetShortListCriteriaFormByOrganizationId } from 'hooks/queries/shortlist-criteria/shortlist-criteria';
import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ConfirmationDialog } from 'components/confirmation-dialog/confirmation-dialog';

import { useIsEnLanguage } from 'hooks/hooks/useIsEnLanguage';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';

import { PageLoader } from '../page-loader/page-loader';
import ActionCellRenderer from './action-cell-renderer';
import { ChipCellRenderer } from './chip-cell-renderer';
import { useAGGridData } from './hooks';
import { LinkCellRenderer } from './link-cell-renderer';

export const InvestmentProjectsTable: FC = () => {
  const { currentLanguage } = useIsEnLanguage();
  const { organizationId } = useParams();

  const { data: investmentProjects, isLoading: investmentProjectsIsLoading } =
    useGetInvestmentProjects(organizationId);

  const {
    mutateAsync: deleteProjectById,
    isLoading: deleteProjectByIdIsLoading,
  } = useDeleteProject();

  const {
    data: investmentProjectMetadata,
    isLoading: investmentProjectMetadataIsLoading,
  } = useGetInvestmentProjectMetadata();

  const {
    data: shortlistCriteriaData,
    isFetching: isFetchingShortlistCriteriaData,
  } = useGetShortListCriteriaFormByOrganizationId(organizationId);

  const [selectedProjectId, setSelectedProjectId] = useState<string | null>(
    null,
  );
  const [isOpenDeleteProjectDialog, setIsOpenDeleteProjectDialog] =
    useState(false);

  const openConfirmationDialog = (id: string) => {
    setSelectedProjectId(id);
    setIsOpenDeleteProjectDialog(true);
  };

  const handleDeleteConfirmation = async () => {
    if (selectedProjectId) {
      await deleteProjectById(selectedProjectId);
      setIsOpenDeleteProjectDialog(false);
      setSelectedProjectId(null);
    }
  };

  const { rowData, columnDefs } = useAGGridData(
    investmentProjects,
    investmentProjectMetadata,
    shortlistCriteriaData,
    openConfirmationDialog,
  );

  if (
    investmentProjectsIsLoading ||
    investmentProjectMetadataIsLoading ||
    isFetchingShortlistCriteriaData
  ) {
    return <PageLoader />;
  }

  return (
    <div className="investment-project-table">
      <div className="ag-theme-quartz" style={{ height: 1000, width: '100%' }}>
        <AgGridReact
          tooltipShowDelay={0}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={{ resizable: true, sortable: true, filter: true }}
          components={{
            actionCellRenderer: ActionCellRenderer,
            chipCellRenderer: ChipCellRenderer,
            linkCellRenderer: LinkCellRenderer,
          }}
        />
      </div>
      <ConfirmationDialog
        title="investment_projects_page.investment_project.delete_project_confirmation"
        args={{
          name: selectedProjectId
            ? investmentProjects?.find((p) => p._id === selectedProjectId)
                ?.name[currentLanguage]
            : '',
        }}
        open={isOpenDeleteProjectDialog}
        onClose={() => setIsOpenDeleteProjectDialog(false)}
        onSubmit={handleDeleteConfirmation}
        isDisabled={deleteProjectByIdIsLoading}
      />
    </div>
  );
};
