export const initialUtilityAssessmentInstitutionalAndEfficiencyAssessmentDataContext =
  {
    isFetchingInstitutionalAndEfficiencyAssessmentData: false,
    data: {},
  };

export const initialUtilityAssessmentTechnicalAndEfficiencyAssessmentDataContext =
  {
    isFetchingTechnicalAndEfficiencyAssessmentData: false,
    preliminaryQuestions: {},
    generalData: {},
    artesianWells: [],
    workOfWaterSupplyService: [],
    operationOfBoostingPumpingStations: [],
    operationOfWastewaterPumpingStations: [],
    comments: [],
  };

export const initialUtilityAssessmentCommercialAndFinancialAssessmentDataContext =
  {
    isFetchingCommercialAndFinancialAssessmentData: false,
    data: {},
  };
