import { useTranslation } from 'react-i18next';
import { Fragment } from 'react/jsx-runtime';

import { Box, Grid, Typography } from '@mui/material';

import { toSnakeCase } from 'utils';

import { TRANSLATION_KEY } from '../constants';
import { QuestionField } from '../question-field/question-field';
import { ProjectFactsheetFormProps } from './types';

export const ProjectFactsheetForm = ({
  data,
  onUpdateForm,
}: ProjectFactsheetFormProps) => {
  const { t } = useTranslation();

  return (
    <form>
      <Grid container spacing={3} p={4}>
        <Grid item xs={12}>
          {Object.entries(data)
            .filter(
              ([key]) =>
                !['_id', '__v', 'organizationId', 'projectId'].includes(key),
            )
            .map(([sectionKey, sectionValue], sectionIndex) => (
              <Fragment key={sectionKey}>
                <Box sx={{ mb: 3 }}>
                  <Typography sx={{ fontWeight: 800, fontSize: 18 }}>
                    {t(`${TRANSLATION_KEY}.${toSnakeCase(sectionKey)}.title`)}
                  </Typography>

                  <Typography>
                    {t(
                      `${TRANSLATION_KEY}.${toSnakeCase(sectionKey)}.description`,
                      '',
                    )}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    mb:
                      sectionIndex < Object.keys(data).length - 1 ? 3 : 'unset',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 3,
                  }}
                >
                  {Object.entries(sectionValue).map(
                    ([questionId, questionValue]) => (
                      <QuestionField
                        key={questionId}
                        questionId={questionId}
                        question={questionValue}
                        sectionKey={sectionKey}
                        translationKey={TRANSLATION_KEY}
                        onUpdateForm={onUpdateForm}
                      />
                    ),
                  )}
                </Box>
              </Fragment>
            ))}
        </Grid>
      </Grid>
    </form>
  );
};
