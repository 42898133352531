import {
  Navigate,
  Outlet,
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';

import { AdminCreateOrganizationPage } from 'pages/admin-create-organization-page/admin-create-organization-page';
import { AdminOrganizationDetailsPage } from 'pages/admin-organization-details-page/admin-organization-details-page';
import { AdminPage } from 'pages/admin-page/admin-page';
import { ErrorPage } from 'pages/error-page/error-page';
import { InvestmentProjectPage } from 'pages/investment-project-page/investment-project-page';
import { ProjectEvaluationPanel } from 'pages/investment-project-page/tab-panels/project-evaluation-panel/project-evaluation-panel';
import { ProjectFactsheetPanel } from 'pages/investment-project-page/tab-panels/project-factsheet-panel/project-factsheet-panel';
import { InvestmentProjectsPage } from 'pages/investment-projects-page/investment-projects-page';
import { OrganizationDetailsPage } from 'pages/organization-details-page/organization-details-page';
import { ShortlistCriteriaPage } from 'pages/shortlist-criteria-page/shortlist-criteria-page';
import { CommercialAndFinancialAssessmentPanel } from 'pages/utility-assessment-page/commercial-and-financial-assessment-panel/commercial-and-financial-assessment-panel';
import { InstitutionalAndEfficiencyAssessmentPanel } from 'pages/utility-assessment-page/institutional-and-efficiency-assessment-panel/institutional-and-efficiency-assessment-panel';
import { GeneralDataTab } from 'pages/utility-assessment-page/technical-and-efficiency-assessment-panel/tabs/general-data-tab/general-data-tab';
import { OperationOfArtesianWellsTab } from 'pages/utility-assessment-page/technical-and-efficiency-assessment-panel/tabs/operation-of-artesian-wells-tab/operation-of-artesian-wells-tab';
import { OperationOfBoostingPumpingStationsTab } from 'pages/utility-assessment-page/technical-and-efficiency-assessment-panel/tabs/operation-of-boosting-pumping-stations-tab/operation-of-boosting-pumping-stations-tab';
import { OperationOfWastewaterPumpingStationsTab } from 'pages/utility-assessment-page/technical-and-efficiency-assessment-panel/tabs/operation-of-wastewater-pumping-stations-tab/operation-of-wastewater-pumping-stations-tab';
import { PreliminaryQuestionsTab } from 'pages/utility-assessment-page/technical-and-efficiency-assessment-panel/tabs/preliminary-questions-tab/preliminary-questions-tab';
import { WorkOfTheWaterSupplyService } from 'pages/utility-assessment-page/technical-and-efficiency-assessment-panel/tabs/work-of-the-water-supply-service/work-of-the-water-supply-service';
import { TechnicalAndEfficiencyAssessmentPanel } from 'pages/utility-assessment-page/technical-and-efficiency-assessment-panel/technical-and-efficiency-assessment-panel';
import { UtilityAssessmentPage } from 'pages/utility-assessment-page/utility-assessment-page';

import { Auth0ProviderWithNavigate } from 'components/auth0-provider-with-navigate/auth0-provider-with-navigate';
import { AuthenticationGuard } from 'components/authentication-guard/authentication-guard';
import { Layout } from 'components/layout/layout';
import { NavigatorByRole } from 'components/navigator-by-role/navigator-by-role';
import { NotAdminRoleGuard } from 'components/not-admin-role-guard/not-admin-role-guard';

import ProjectDetailsPanel from '../pages/investment-project-page/tab-panels/project-details-panel/project-details-panel';
import {
  InvestmentPlanRoutes,
  InvestmentProjectRoutes,
  Routes,
  TechnicalAndEfficiencyAssessmentRoutes,
  UtilityAssessmentRoutes,
} from './constants';

const Auth0ProviderLayout = () => (
  <Auth0ProviderWithNavigate>
    <Outlet />
  </Auth0ProviderWithNavigate>
);

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<Auth0ProviderLayout />}>
      <Route
        element={<AuthenticationGuard component={() => <Layout />} />}
        errorElement={<ErrorPage />}
      >
        <Route element={<NavigatorByRole />} path="/" />

        <Route
          element={<OrganizationDetailsPage />}
          path={`${Routes.ORGANIZATIONS}/:organizationId`}
        />

        <Route
          element={
            <NotAdminRoleGuard>
              <AdminPage />
            </NotAdminRoleGuard>
          }
          path={`${Routes.ADMIN}/${Routes.ORGANIZATIONS}`}
        />

        <Route
          element={
            <NotAdminRoleGuard>
              <AdminCreateOrganizationPage />
            </NotAdminRoleGuard>
          }
          path={`${Routes.ADMIN}/${Routes.ORGANIZATIONS}/new`}
        />

        <Route
          element={
            <NotAdminRoleGuard>
              <AdminOrganizationDetailsPage />
            </NotAdminRoleGuard>
          }
          path={`${Routes.ADMIN}/${Routes.ORGANIZATIONS}/:organizationId`}
        />

        {/*<Route*/}
        {/*  element={<GeneralInformationPage />}*/}
        {/*  path={`${Routes.ORGANIZATIONS}/:organizationId/${Routes.PROCESSES}/:processId/${Routes.STEPS}/:stepId/${Routes.GENERAL_INFORMATION_SURVEY}`}*/}
        {/*/>*/}

        {/*<Route*/}
        {/*  element={<Navigate to={DetailedAssessmentRoutes.DECOMPOSITION} />}*/}
        {/*  path={`${Routes.ORGANIZATIONS}/:organizationId/${Routes.PROCESSES}/:processId/${Routes.STEPS}/:stepId/${Routes.DETAILED_ASSESSMENT_SURVEY}`}*/}
        {/*/>*/}

        {/*<Route*/}
        {/*  element={<DetailedAssessmentPage />}*/}
        {/*  path={`${Routes.ORGANIZATIONS}/:organizationId/${Routes.PROCESSES}/:processId/${Routes.STEPS}/:stepId/${Routes.DETAILED_ASSESSMENT_SURVEY}`}*/}
        {/*>*/}
        {/*  <Route*/}
        {/*    path={DetailedAssessmentRoutes.DECOMPOSITION}*/}
        {/*    element={<DecompositionPanel />}*/}
        {/*  />*/}

        {/*  <Route*/}
        {/*    path={DetailedAssessmentRoutes.RISK_MATRIX}*/}
        {/*    element={<RiskMatrixPanelWrapper />}*/}
        {/*  />*/}

        {/*  <Route*/}
        {/*    path={DetailedAssessmentRoutes.POTENTIAL_FAILURES}*/}
        {/*    element={<PotentialFailuresPanelWrapper />}*/}
        {/*  />*/}

        {/*  <Route*/}
        {/*    path={DetailedAssessmentRoutes.RECOMMENDED_PRIORITY}*/}
        {/*    element={<RecommendedPriorityPanel />}*/}
        {/*  />*/}
        {/*</Route>*/}

        <Route
          path={`${Routes.ORGANIZATIONS}/:organizationId/${Routes.INVESTMENT_PLAN}/`}
        >
          <Route
            element={
              <Navigate
                to={
                  UtilityAssessmentRoutes.INSTITUTIONAL_AND_EFFICIENCY_ASSESSMENT
                }
              />
            }
            path={InvestmentPlanRoutes.UTILITY_ASSESSMENT}
          />

          <Route
            element={<UtilityAssessmentPage />}
            path={InvestmentPlanRoutes.UTILITY_ASSESSMENT}
          >
            <Route
              path={
                UtilityAssessmentRoutes.INSTITUTIONAL_AND_EFFICIENCY_ASSESSMENT
              }
              element={<InstitutionalAndEfficiencyAssessmentPanel />}
            />

            <Route
              path={UtilityAssessmentRoutes.TECHNICAL_AND_EFFICIENCY_ASSESSMENT}
              element={<TechnicalAndEfficiencyAssessmentPanel />}
            >
              <Route
                index
                element={
                  <Navigate
                    to={
                      TechnicalAndEfficiencyAssessmentRoutes.PRELIMINARY_QUESTIONS
                    }
                    replace
                  />
                }
              />

              <Route
                path={
                  TechnicalAndEfficiencyAssessmentRoutes.PRELIMINARY_QUESTIONS
                }
                element={<PreliminaryQuestionsTab />}
              />

              <Route
                path={TechnicalAndEfficiencyAssessmentRoutes.GENERAL_DATA}
                element={<GeneralDataTab />}
              />

              <Route
                path={
                  TechnicalAndEfficiencyAssessmentRoutes.OPERATION_OF_ARTESIAN_WELLS
                }
                element={<OperationOfArtesianWellsTab />}
              />

              <Route
                path={
                  TechnicalAndEfficiencyAssessmentRoutes.WORK_OF_THE_WATER_SUPPLY_SERVICE
                }
                element={<WorkOfTheWaterSupplyService />}
              />

              <Route
                path={
                  TechnicalAndEfficiencyAssessmentRoutes.OPERATION_OF_BOOSTING_PUMPING_STATIONS
                }
                element={<OperationOfBoostingPumpingStationsTab />}
              />

              <Route
                path={
                  TechnicalAndEfficiencyAssessmentRoutes.OPERATION_OF_WASTEWATER_PUMPING_STATIONS
                }
                element={<OperationOfWastewaterPumpingStationsTab />}
              />
            </Route>

            <Route
              path={UtilityAssessmentRoutes.COMMERCIAL_AND_FINANCIAL_ASSESSMENT}
              element={<CommercialAndFinancialAssessmentPanel />}
            />
          </Route>

          <Route
            element={<ShortlistCriteriaPage />}
            path={InvestmentPlanRoutes.SHORTLIST_CRITERIA}
          />

          <Route
            element={<InvestmentProjectsPage />}
            path={InvestmentPlanRoutes.INVESTMENT_PROJECTS}
          />

          <Route
            element={<InvestmentProjectPage />}
            path={`${InvestmentPlanRoutes.INVESTMENT_PROJECTS}/:investmentProjectId`}
          >
            <Route
              path={InvestmentProjectRoutes.PROJECT_DETAILS}
              element={<ProjectDetailsPanel />}
            />

            <Route
              path={InvestmentProjectRoutes.EVALUATION}
              element={<ProjectEvaluationPanel />}
            />

            <Route
              path={InvestmentProjectRoutes.PROJECT_FACTSHEETS}
              element={<ProjectFactsheetPanel />}
            />

            <Route
              path={InvestmentProjectRoutes.FEASIBILITY_IN_DETAILS}
              element={<>Feasibility in details</>}
            />
            <Route path="" element={<ProjectDetailsPanel />} />
          </Route>
        </Route>
      </Route>
    </Route>,
  ),
);
