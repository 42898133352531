import { FC, Fragment, useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Box, Divider, Grid, Typography } from '@mui/material';

import { RenderSelect } from 'pages/utility-assessment-page/components/select';
import { RenderTextInput } from 'pages/utility-assessment-page/components/text-input';
import { UtilityAssessmentContext } from 'pages/utility-assessment-page/context';

import { InputLinkUpload } from 'components/link-upload/link-upload';
import { PageLoader } from 'components/page-loader/page-loader';

import { toSnakeCase } from 'utils';

import { useIsEnLanguage } from 'hooks/hooks/useIsEnLanguage';

import { getFormattedValue } from '../utils';
import { InstitutionalAndEfficiencyAssessmentFormProps } from './types';

export const InstitutionalAndEfficiencyAssessmentForm: FC<
  InstitutionalAndEfficiencyAssessmentFormProps
> = ({ onUpdateForm }) => {
  const { t } = useTranslation();
  const { currentLanguage } = useIsEnLanguage();
  const { control } = useFormContext();
  const {
    institutionalAndEfficiencyAssessmentTab: {
      data,
      isFetchingInstitutionalAndEfficiencyAssessmentData: isFetching,
    },
  } = useContext(UtilityAssessmentContext);

  return (
    <Grid container spacing={3} p={4} justifyContent="center">
      {isFetching ? (
        <PageLoader height="100%" />
      ) : (
        Object.entries(data)
          .filter(([key]) => !['_id'].includes(key))
          .map(([questionKey, questionValue], index) => (
            <Grid item key={questionKey} xs={12}>
              <Typography sx={{ fontWeight: 800, mb: 1 }}>
                {t(
                  `utility_assessment_page.institutional_and_efficiency_assessment.${toSnakeCase(
                    questionKey,
                  )}`,
                )}
              </Typography>

              <Grid container spacing={2}>
                {Object.entries(questionValue.years).map(([year, value]) => {
                  if (value && typeof value === 'object' && 'value' in value) {
                    return (
                      <Fragment key={year}>
                        <Grid item xs={12}>
                          <RenderTextInput
                            name={`${questionKey}.years.${year}.value.${currentLanguage}`}
                            label={year}
                            isMultiline
                            isDirtyCallback={onUpdateForm}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <InputLinkUpload
                            control={control}
                            fieldName={`${questionKey}.years.${year}.attachments`}
                            onUpdateTemplate={() =>
                              onUpdateForm(
                                `${questionKey}.years.${year}.attachments`,
                              )
                            }
                          />
                        </Grid>
                      </Fragment>
                    );
                  }

                  if (value && typeof value === 'object' && 'en' in value) {
                    return (
                      <Grid item xs={12} key={year}>
                        <RenderTextInput
                          name={`${questionKey}.years.${year}.${currentLanguage}`}
                          label={year}
                          isMultiline
                          isDirtyCallback={onUpdateForm}
                        />
                      </Grid>
                    );
                  }

                  if ('options' in questionValue) {
                    return (
                      <Grid item xs key={year}>
                        <RenderSelect
                          name={`${questionKey}.years.${year}`}
                          options={questionValue.options}
                          label={year}
                          isDirtyCallback={onUpdateForm}
                        />
                      </Grid>
                    );
                  }

                  if (questionKey === 'countOfStaffPerConnections') {
                    const countOfStaffPerConnectionsValue =
                      data.countOfStaffPerConnections.years[Number(year)];
                    const formattedValue = getFormattedValue(
                      countOfStaffPerConnectionsValue,
                    );

                    return (
                      <Grid item xs key={year}>
                        <Box>
                          <Typography
                            sx={{ fontSize: 14, fontWeight: 700, mb: 0.5 }}
                          >
                            {year}
                          </Typography>
                          <Typography>{t(formattedValue)}</Typography>
                        </Box>
                      </Grid>
                    );
                  }

                  return (
                    <Grid item xs key={year}>
                      <RenderTextInput
                        name={`${questionKey}.years.${year}`}
                        label={year}
                        type="number"
                        isDirtyCallback={onUpdateForm}
                      />
                    </Grid>
                  );
                })}

                {'explanation' in questionValue && (
                  <Grid item xs={12}>
                    <RenderTextInput
                      name={`${questionKey}.explanation.${currentLanguage}`}
                      label={t('utility_assessment_page.explanation')}
                      isMultiline
                      isDirtyCallback={onUpdateForm}
                    />
                  </Grid>
                )}
              </Grid>

              {index < Object.keys(data).length - 1 && (
                <Divider sx={{ mt: 3 }} />
              )}
            </Grid>
          ))
      )}
    </Grid>
  );
};
