import { FC, useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Divider, Grid, Typography } from '@mui/material';

import { RenderSelect } from 'pages/utility-assessment-page/components/select';
import { RenderTextInput } from 'pages/utility-assessment-page/components/text-input';
import { UtilityAssessmentContext } from 'pages/utility-assessment-page/context';

import { InputLinkUpload } from 'components/link-upload/link-upload';
import { PageLoader } from 'components/page-loader/page-loader';

import { toSnakeCase } from 'utils';

import { useIsEnLanguage } from 'hooks/hooks/useIsEnLanguage';

import { CommercialAndFinancialAssessmentFormProps } from './types';

export const CommercialAndFinancialAssessmentForm: FC<
  CommercialAndFinancialAssessmentFormProps
> = ({ onUpdateForm }) => {
  const { t } = useTranslation();
  const { currentLanguage } = useIsEnLanguage();
  const { control } = useFormContext();
  const {
    commercialAndFinancialAssessmentTab: {
      data,
      isFetchingCommercialAndFinancialAssessmentData: isFetching,
    },
  } = useContext(UtilityAssessmentContext);

  return (
    <Grid container spacing={3} p={4} justifyContent="center">
      {isFetching ? (
        <PageLoader height="100%" />
      ) : (
        Object.entries(data)
          .filter(([key]) => !['_id'].includes(key))
          .map(([questionKey, questionValue], index) => (
            <Grid item key={questionKey} xs={12}>
              <Typography sx={{ fontWeight: 800, mb: 1 }}>
                {t(
                  `utility_assessment_page.commercial_and_financial_assessment.${toSnakeCase(
                    questionKey,
                  )}`,
                )}
              </Typography>

              <Grid container spacing={2}>
                {Object.entries(questionValue.years).map(([year, value]) => {
                  if (
                    value &&
                    typeof value === 'object' &&
                    'en' in value &&
                    'ua' in value
                  ) {
                    return (
                      <Grid item xs={12} key={year}>
                        <RenderTextInput
                          name={`${questionKey}.years.${year}.${currentLanguage}`}
                          label={year}
                          isMultiline={questionValue.isMultiline}
                          isDirtyCallback={onUpdateForm}
                        />
                      </Grid>
                    );
                  }

                  if ('options' in questionValue) {
                    return (
                      <Grid item xs key={year}>
                        <RenderSelect
                          name={`${questionKey}.years.${year}`}
                          options={questionValue.options}
                          label={year}
                          isDirtyCallback={onUpdateForm}
                        />
                      </Grid>
                    );
                  }

                  return (
                    <Grid item xs key={year}>
                      <RenderTextInput
                        name={`${questionKey}.years.${year}`}
                        label={year}
                        type="number"
                        isDirtyCallback={onUpdateForm}
                      />
                    </Grid>
                  );
                })}

                {'explanation' in questionValue && (
                  <Grid item xs={12}>
                    <RenderTextInput
                      name={`${questionKey}.explanation.${currentLanguage}`}
                      label={t(
                        `utility_assessment_page.${
                          questionKey === 'totalAnnualRevenue'
                            ? 'commercial_and_financial_assessment.total_annual_revenue_explanation'
                            : 'explanation'
                        }`,
                      )}
                      isMultiline
                      isDirtyCallback={onUpdateForm}
                    />
                  </Grid>
                )}

                {'attachments' in questionValue && (
                  <Grid item xs>
                    <InputLinkUpload
                      control={control}
                      fieldName={`${questionKey}.attachments`}
                      onUpdateTemplate={() =>
                        onUpdateForm(`${questionKey}.attachments`)
                      }
                    />
                  </Grid>
                )}
              </Grid>

              {index < Object.keys(data).length - 1 && (
                <Divider sx={{ mt: 3 }} />
              )}
            </Grid>
          ))
      )}
    </Grid>
  );
};
