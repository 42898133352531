import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  Box,
  FormControlLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';

import { COMMON_FORM_CONTROL_LABEL_STYLES } from 'constants/index';

import { getValue, toSnakeCase } from 'utils';

import { useIsEnLanguage } from 'hooks/hooks/useIsEnLanguage';

import { TRANSLATION_KEY } from '../constants';
import { PercentageField } from '../percentage-field/percentage-field';
import { StyledExplanationBoxWrapper } from '../styles';
import { shouldShowOptionalField } from '../utils';
import { QuestionFieldProps } from './types';

export const QuestionField: FC<QuestionFieldProps> = ({
  questionId,
  question,
  sectionKey,
  translationKey,
  onUpdateForm,
}) => {
  const { t } = useTranslation();
  const { currentLanguage } = useIsEnLanguage();
  const { control, watch } = useFormContext();

  const { options, isMultiline } = question;

  const fieldValue = watch(`${sectionKey}.${questionId}.value`);
  const showOptionalField = shouldShowOptionalField(options, fieldValue);

  return (
    <Box sx={{ width: '100%' }}>
      <FormControlLabel
        sx={COMMON_FORM_CONTROL_LABEL_STYLES}
        control={
          options ? (
            <StyledExplanationBoxWrapper>
              <Controller
                name={`${sectionKey}.${questionId}.value`}
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    size="small"
                    fullWidth
                    value={field.value || ''}
                    onBlur={() => onUpdateForm(field.name)}
                  >
                    {options.map((option) => (
                      <MenuItem key={option} value={option}>
                        {t(`dropdown_options.${option}`, option)}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />

              {showOptionalField && (
                <Controller
                  name={`${sectionKey}.${questionId}.explanation.${currentLanguage}`}
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <FormControlLabel
                      sx={COMMON_FORM_CONTROL_LABEL_STYLES}
                      control={
                        <TextField
                          {...field}
                          error={!!error}
                          helperText={error?.message}
                          value={getValue(field.value, currentLanguage)}
                          onBlur={() => onUpdateForm(field.name)}
                          size="small"
                          fullWidth
                          multiline
                          rows={6}
                        />
                      }
                      label={
                        <Typography
                          sx={{
                            fontSize: 14,
                            fontWeight: 700,
                            mb: 0.5,
                          }}
                        >
                          {t(`${TRANSLATION_KEY}.explain`, {
                            field: t(
                              `${translationKey}.${toSnakeCase(sectionKey)}.${toSnakeCase(questionId)}.title`,
                            ).toLowerCase(),
                          })}
                        </Typography>
                      }
                      labelPlacement="top"
                    />
                  )}
                />
              )}
            </StyledExplanationBoxWrapper>
          ) : questionId === 'fundingBreakdown' ? (
            <PercentageField
              questionId={questionId}
              sectionKey={sectionKey}
              translationKey={translationKey}
              onUpdateForm={onUpdateForm}
            />
          ) : (
            <Controller
              name={`${sectionKey}.${questionId}.value.${currentLanguage}`}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  error={!!error}
                  helperText={error?.message}
                  value={getValue(field.value, currentLanguage)}
                  onBlur={() => onUpdateForm(field.name)}
                  size="small"
                  fullWidth
                  multiline={isMultiline}
                  rows={isMultiline ? 6 : 1}
                />
              )}
            />
          )
        }
        label={
          <Box sx={{ mb: 0.5 }}>
            <Typography sx={{ fontSize: 14, fontWeight: 700 }}>
              {t(
                `${translationKey}.${toSnakeCase(sectionKey)}.${toSnakeCase(questionId)}.title`,
              )}
            </Typography>

            <Typography sx={{ fontSize: 14 }}>
              {t(
                `${translationKey}.${toSnakeCase(sectionKey)}.${toSnakeCase(questionId)}.description`,
                '',
              )}
            </Typography>
          </Box>
        }
        labelPlacement="top"
      />
    </Box>
  );
};
