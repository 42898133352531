import { useUpdateProjectFactsheetByProjectId } from 'hooks/mutations/project-factsheet/project-factsheet';
import { useGetProjectFactsheetByProjectId } from 'hooks/queries/project-factsheet/project-factsheet';
import { useContext, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { InvestmentProjectRoutes } from 'routes/constants';

import { CircularProgress } from '@mui/material';

import { AppContext } from 'context';

import { ProjectFactsheetForm } from './project-factsheet-form/project-factsheet-form';

export const ProjectFactsheetPanel = () => {
  const { investmentProjectId } = useParams();
  const {
    commentsUniquePathId: { setCommentsUniquePathId },
  } = useContext(AppContext);

  const {
    data: projectFactsheetData,
    isLoading: isLoadingProjectFactsheetData,
  } = useGetProjectFactsheetByProjectId(investmentProjectId);
  const { mutateAsync: updateProjectFactsheetByProjectId } =
    useUpdateProjectFactsheetByProjectId();

  const methods = useForm({ defaultValues: undefined });
  const { reset, watch } = methods;

  const handleUpdateFormOnBlur = async (fieldName: string) => {
    const value = watch(fieldName);

    if (value === null) return;

    const data = {
      path: fieldName,
      value,
    };

    await updateProjectFactsheetByProjectId({
      projectId: investmentProjectId,
      data,
    });
  };

  useEffect(() => {
    if (!isLoadingProjectFactsheetData) {
      const uniqueCommentsPath = `${investmentProjectId}${InvestmentProjectRoutes.PROJECT_FACTSHEETS}`;

      setCommentsUniquePathId(uniqueCommentsPath);
    }
  }, [
    isLoadingProjectFactsheetData,
    investmentProjectId,
    setCommentsUniquePathId,
  ]);

  useEffect(() => {
    if (!isLoadingProjectFactsheetData && projectFactsheetData) {
      reset(projectFactsheetData);
    }
  }, [projectFactsheetData, isLoadingProjectFactsheetData, reset]);

  if (isLoadingProjectFactsheetData || !projectFactsheetData) {
    return <CircularProgress />;
  }

  return (
    <FormProvider {...methods}>
      <ProjectFactsheetForm
        data={projectFactsheetData}
        onUpdateForm={handleUpdateFormOnBlur}
      />
    </FormProvider>
  );
};
